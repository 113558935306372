import * as _ from 'lodash'

export class FetchError extends Error {
  constructor({ endpoint, status }) {
    super(`Failed to fetch ${endpoint} with status code ${status}`)

    this.name = 'FetchError'
  }
}

export class UploadFileError extends Error {
  public readonly data

  constructor(error) {
    const errorDescription = _.get(error, 'errorDescription')
    super(`Failed to upload file${errorDescription ? `: ${errorDescription}` : ''}`)

    this.name = 'UploadFileError'
    this.data = error
  }
}

export class NetworkError extends Error {
  constructor({ endpoint }) {
    super(`Failed to fetch ${endpoint}`)

    this.name = 'NetworkError'
  }
}

export class RegistrationError extends Error {
  public readonly data

  constructor(message, data?) {
    super(message)

    this.name = 'RegistrationError'
    this.data = data
  }
}
