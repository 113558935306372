import { FIELDS, ROLE_FORM, ROLE_SUBMIT_BUTTON } from '../../constants/roles'
import * as _ from 'lodash'
import { SecondsToResetDefaults, SuccessActionTypes } from '../../constants/success-settings'
import {
  isCaptchaField,
  isRadioGroup,
  isUploadButton,
  replaceMessageInnerText,
  toMiliseconds,
  getBaseUrl,
} from '../viewer-utils'
import { post } from '../services/fetch-utils'
import { FormsFieldPreset } from '../../constants/field-types'
import { EmailConfig, Field, SubmitFormRequest, SubmitFormResponse } from '../../types/domain-types'
import { createFieldDto, getFieldValue } from '../field-dto/field-dto'
import { EMPTY_EMAIL_ID, isEmptyEmailId } from '../../utils/utils'
import { submitUtils } from '../submit-utils'
import { siteStore } from '../stores/site-store'

// TODO: Cover this file (registration form) with tests

const getRecipients = (emailId?: string, secondEmailId?: string) => {
  let sendToOwner: boolean = false
  const emailIds: string[] = []

  if (_.isEmpty(emailId)) {
    // support backwards compatibility when emailId is empty string
    sendToOwner = true
  } else {
    if (emailId !== EMPTY_EMAIL_ID) {
      // send to other recipients
      emailIds.push(emailId)
    }
  }

  if (!isEmptyEmailId(secondEmailId)) {
    emailIds.push(secondEmailId)
  }

  return { sendToOwner, emailIds }
}

const createEmailConfig = (emailId, secondEmailId): EmailConfig => {
  const recipients = getRecipients(emailId, secondEmailId)

  if (recipients.sendToOwner) {
    return {
      sendToOwnerAndEmails: {
        emailIds: [...recipients.emailIds],
      },
    }
  }

  return {
    sendToEmails: {
      emailIds: [...recipients.emailIds],
    },
  }
}

const FILTERED_FIELDS = [FormsFieldPreset.GENERAL_RECAPTCHA]

const createFieldsDto = (fields, attachments) => {
  const fieldsDto = []

  const validFields = _.filter(
    fields,
    field => !_.includes(FILTERED_FIELDS, _.get(field, 'connectionConfig.fieldType'))
  )

  _.forEach(validFields, field => {
    const fieldDto: Field = createFieldDto(field, attachments)
    fieldsDto.push(fieldDto)
  })

  return fieldsDto
}

const enrichPayloadWithCaptcha = ({ $w, payload }) => {
  const captchaField = $w(`@${FIELDS.ROLE_FIELD_RECAPTCHA}`)

  if (captchaField.length > 0) {
    const value = getFieldValue(captchaField)
    payload.security = { captcha: value }
  }
}

const sendActivity = async ($w, { attachments, fields, wixWindow }) => {
  siteStore.interactionStarted('submission')

  const form = $w(`@${ROLE_FORM}`)
  const { emailId, secondEmailId, labels, formName = '' } = form.connectionConfig

  const fieldsDto: Field[] = createFieldsDto(fields, attachments)
  const emailConfig: EmailConfig = createEmailConfig(emailId, secondEmailId)

  const payload: SubmitFormRequest = {
    formProperties: {
      formName,
      formId: form.uniqueId,
    },
    emailConfig,
    viewMode: wixWindow.viewMode,
    fields: fieldsDto,
    labelIds: _.compact(labels),
  }

  enrichPayloadWithCaptcha({ $w, payload })

  const shouldDisableRetry = _.has(payload, 'security')
  const baseUrl = getBaseUrl()

  const response = await post<SubmitFormResponse>(
    baseUrl,
    '_api/wix-forms/v1/submit-form',
    payload,
    shouldDisableRetry
  )

  siteStore.interactionEnded('submission')

  return response
}

const navigateToNextStep = $w => {
  const $multiStepForm = $w(`@${ROLE_FORM}`)
  return submitUtils.navigateToStepByOffset($multiStepForm, 1)
}

const showSuccessMessageIfExists = ({
  $message,
  secondsToResetForm,
  successActionType,
  successLinkValue,
  wixLocation,
  $w,
  isMultiStepForm,
}) => {
  switch (successActionType) {
    case SuccessActionTypes.LINK:
    case SuccessActionTypes.EXTERNAL_LINK:
      setTimeout(() => wixLocation.to(siteStore.platformApi.links.toUrl(successLinkValue)), 100)
      return Promise.resolve()

    case SuccessActionTypes.DOWNLOAD_DOCUMENT:
      if (_.get($message, 'html', undefined) === undefined) {
        return Promise.resolve()
      }
      replaceMessageInnerText(
        $message,
        innerText =>
          `<a href="${siteStore.platformApi.links.toUrl(
            successLinkValue
          )}" target="_blank" role="alert">${innerText}</a>`
      )
      $message.show()
      return isMultiStepForm ? navigateToNextStep($w) : Promise.resolve()

    default:
      const hasMessageContent = _.get($message, 'html', undefined) !== undefined
      if (hasMessageContent) {
        replaceMessageInnerText($message, innerText => `<span role="alert">${innerText}</span>`)
        $message.show()
      }

      if (isMultiStepForm) {
        return navigateToNextStep($w)
      }

      return hasMessageContent &&
        secondsToResetForm >= SecondsToResetDefaults.MIN &&
        secondsToResetForm <= SecondsToResetDefaults.MAX
        ? new Promise(resolve =>
            setTimeout(() => resolve($message.hide()), toMiliseconds(secondsToResetForm))
          )
        : Promise.resolve()
  }
}

export class FormStrategy {
  constructor(protected submitArgs) {}

  static isEnabled($w) {
    return $w(`@${ROLE_SUBMIT_BUTTON}`)[0]
  }

  registerCustomValidation(_submitArgs) {}

  validateFields(fields: any) {
    const fieldsToTestValidity = _.filter(
      fields,
      field => !isUploadButton(field) || (field.required && field.value.length === 0)
    )

    return _.reject(fieldsToTestValidity, field => {
      if (isRadioGroup(field)) {
        // TODO - waiting for full fix for radioGroup
        return !field.required || field.value.length > 0
      }

      if (isCaptchaField(field)) {
        return !_.isEmpty(field.token)
      }

      if ('valid' in field) {
        return field.valid
      }

      return true
    })
  }

  async execute({ attachments, fields, skipSendActivity = false }): Promise<SubmitFormResponse> {
    if (skipSendActivity) return Promise.resolve({})

    const { $w, wixWindow } = this.submitArgs

    return sendActivity($w, {
      attachments,
      fields,
      wixWindow,
    })
  }

  async postSubmission() {
    const {
      $message,
      secondsToResetForm,
      successActionType,
      successLinkValue,
      wixLocation,
      isMultiStepForm,
      $w,
    } = this.submitArgs
    await showSuccessMessageIfExists({
      $w,
      isMultiStepForm,
      $message,
      secondsToResetForm,
      successActionType,
      successLinkValue,
      wixLocation,
    })
  }
}
